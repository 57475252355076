import $ from "jquery";

export const parallax = (elem, point) => {
  const initScrollTop = $(window).scrollTop();
  const parallaxEl = $(elem);
  parallaxEl.css({'top': (initScrollTop / point) + '%'});

  $(window).scroll(function () {

    var scrollTop = $(window).scrollTop();

    parallaxEl.css({'top': (scrollTop / point) + '%'});

  });
};
