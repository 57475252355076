import $ from "jquery";

function menuHover() {
  if (window.innerWidth > 768) {
    $('.menu-item-has-children').mouseenter(function () {
      $(this).children('.submenu').show().css("transform", "translateY(0)");
    });
    $('.menu-item-has-children').mouseleave(function () {
      $(this).children('.submenu').show().css("transform", "translateY(-100%)");
    });

    $('.submenu').mouseenter(function () {
      $(this).prev().addClass('active');
    });

    $('.submenu').mouseleave(function () {
      $(this).prev().removeClass('active');
    });

    $('.menu-item-has-children a').focus(function () {
      $(this).next('.submenu').show().css("transform", "translateY(0)");
    });

    $('.menu-item-has-children .submenu .menu-item:last-of-type a').blur(function () {
      $(this).parent().parent('.submenu').show().css("transform", "translateY(-100%)");
    });
  } else {
    $('.menu-item-has-children').mouseenter(function () {
      $(this).children('.submenu').show();
    });
    $('.menu-item-has-children').mouseleave(function () {
      $(this).children('.submenu').show();
    });
  }
}

$(document).ready(() => {
  const nav = document.querySelector('#nav-main');
  const menuToggle = document.querySelector('.header__hamburger button');

  menuToggle.addEventListener('click', e => {
    e.preventDefault();
    menuToggle.toggleAttribute('aria-expanded');
    nav.classList.toggle('nav-main--open');
  });

  menuHover();
});

window.addEventListener('resize', () => {
  if (window.innerWidth < 768) {
    $('.submenu').show().css("transform", "translateY(0)");
  } else {
    $('.submenu').hide().css("transform", "translateY(-100%)");
  }

  menuHover();

});
