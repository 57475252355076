import $ from "jquery";

window.addEventListener('load', () => {
  $(function () {
    $('a[href*="#"]:not([href="#"])').click(function () {
      if (
        location.pathname.replace(/^\//, "") ==
        this.pathname.replace(/^\//, "") &&
        location.hostname == this.hostname
      ) {
        let target = $(this.hash);
        const headerHeight = $("header.header").outerHeight();

        const nav = document.querySelector('#nav-main');
        const menuToggle = document.querySelector('.header__hamburger button');

        target = target.length
          ? target
          : $("[name=" + this.hash.slice(1) + "]");
        if (target.length) {
          nav.classList.remove('nav-main--open');
          menuToggle.removeAttribute('aria-expanded');
          $("html, body").animate(
            {
              scrollTop: target.offset().top - headerHeight,
            },
            1000
          );
          return false;
        }
      }
    });
  });
});
