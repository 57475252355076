import $ from "jquery";

function labelAnimation(input) {
  input.each(function () {
    let labelName = $(this).attr("id");
    let labelInput = $(`label[for='${labelName}']`);
    if ($(this).val()) {
      labelInput.addClass('active');
    }
  });

  input.on('focus', function () {
    let labelName = $(this).attr("id");
    let labelInput = $(`label[for='${labelName}']`);
    if (!$(this).val()) {
      labelInput.addClass('active');
    }
  });

  input.on('focusout', function () {
    let labelName = $(this).attr("id");
    let labelInput = $(`label[for='${labelName}']`);
    if (!$(this).val()) {
      labelInput.removeClass('active');
      labelInput.show();
    }
  });
}

$(document).ready(function () {
  labelAnimation($('.gfield input'), 'load');
});

(function ($) {
  $(document).on('gform_post_render', function () {
    labelAnimation($('.gfield input'));
  });
})(jQuery);
