import {library, dom} from "@fortawesome/fontawesome-svg-core";
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faInstagram,
} from "@fortawesome/free-brands-svg-icons";

library.add(
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faInstagram,
);
dom.watch();
