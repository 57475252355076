const calcDeltaSight = (oldSight, newSight) => {
    return oldSight !== newSight ? [`<span>${oldSight}</span>`, `<span>${newSight}</span>`] : [`<span>${newSight}</span>`];
}

const calcDeltaBetweenNumbers = (oldNumber, newNumber) => {
    let numberArray = [oldNumber];
    let notFound = true;
    if (oldNumber === newNumber) return numberArray.map(x => `<span>${x}</span>`);
    while (notFound) {
        oldNumber++;
        if (oldNumber > 9) oldNumber = 0;
        numberArray.push(oldNumber);
        if (oldNumber === newNumber) notFound = false;
    }
    return numberArray.map(x => `<span>${x}</span>`);
}

const createNumberHTML = (numbers, old, element) => {
    for (let i = 0; i < numbers.length; i++) {
        if (isNaN(numbers[i]) || isNaN(old[i])) {
            element.insertAdjacentHTML(
                "beforeend",
                `<span data-value="${calcDeltaSight(old[i], numbers[i]).length + 1}"><span></span>${calcDeltaSight(old[i], numbers[i]).join('')}</span>`
                );
        } else {
            element.insertAdjacentHTML(
                "beforeend",
                `<span data-value="${calcDeltaBetweenNumbers(old[i], numbers[i]).length + 1}"><span></span>${calcDeltaBetweenNumbers(old[i], numbers[i]).join('')}</span>`
            );
        }
    }
    return element;
}

const animateNumber = (number, old, element) => {
    element.innerHTML = '';
    const numberArray = number;
    createNumberHTML(numberArray, old, element);
    const ticks = [...element.querySelectorAll("span[data-value]")];
    setTimeout(() => {
        let i = 1;
        for (let tick of ticks) {
            let dist = parseInt(tick.getAttribute("data-value") - 1);
            tick.style.transform = `translateY(-${dist * 100}%)`;
            if(i !== ticks.length) tick.style.transitionDuration = `${i * 0.3}s`;
            i++;
        }
    }, 0);
    old = numberArray;
}

export const counterInit = (box) => {
    const counter = box.querySelector('.numbers');
    if(!counter) return;

    const defaultValue = [...counter.innerHTML];
    const defaultNumber = defaultValue.map(item => Number.isInteger(+item) ? +item : item);
    let old = defaultNumber.map((item, index) => {
        if(Number.isInteger(+item)) item = index === defaultNumber.length - 1 ?  +item < 9 ? +item + 1 : 0 : item;
        return item;
    });

    animateNumber(defaultNumber, old, counter);
};
