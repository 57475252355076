const updateVariables = (slider, r, duration, variable) => {
    const sliderContainer = slider.querySelectorAll('.animation-slider-container');
    const mainWight = [...sliderContainer][0].clientWidth;
    const speadCoef = mainWight / duration;
      
    [...sliderContainer].forEach((desk, index) => {
        const deskSpead = desk.clientWidth / speadCoef;
        r.style.setProperty(`--${variable}-${index}`, `${deskSpead}s`);
    });
}

export const updateAnimationSliderDuration = () => {
  const animationSlider = document.querySelectorAll('.wp-block-cgb-animation-slider');
  const r = document.querySelector(':root');
  if(!animationSlider) return;

  animationSlider.forEach(slider => {
    const animationSliderDesk = slider.querySelector('.animation-slider--desk');
    const animationSliderMobile = slider.querySelector('.animation-slider--mobile');
    
    updateVariables(animationSliderDesk, r, 50, 'line-desc');
    updateVariables(animationSliderMobile, r, 40, 'line-mob');
  });
}