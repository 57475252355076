export const headerHeight = () => {
  const r = document.querySelector(':root');
  const headerHeight = document.querySelector("header.header").offsetHeight;
  r.style.setProperty('--sk-header-height', `${headerHeight}px`);
}

export const footerHeight = () => {
  if (!document.querySelector('.footer') && !document.querySelector('.footer__section')) return;
  const footerSection = document.querySelector('.footer__section');
  document.querySelector('.footer').style.height = footerSection.offsetHeight + 'px';
    return footerSection.offsetHeight;
};

export const footerHeightResize = () => {
  if (!document.querySelector('.footer') && !document.querySelector('.footer__section')) return;
  const sectionFooterHeight = document.querySelector('.footer__section').offsetHeight;
  document.querySelector('.footer').style.height = sectionFooterHeight + 'px';
  return sectionFooterHeight;
};

export const showFooter = () => {
  const footerSection = document.querySelector('.footer__section');
  if (!footerSection) return;

  const scrollCoeff = (footerSection.offsetHeight - 300) * (document.body.scrollHeight - (window.scrollY + window.innerHeight)) / window.innerHeight;
  const opacityCoeff = Math.floor((window.innerHeight - (document.querySelector('main').getBoundingClientRect().bottom + footerSection.offsetHeight)) * -1);
  const opacity = 1 - (opacityCoeff / footerSection.offsetHeight);

  if (footerSection.offsetHeight < window.innerHeight) {
    footerSection.style.position = 'fixed';
    if (opacity > 0) footerSection.style.opacity = opacity;
    document.querySelector('.footer__wrapper').style.transform = `translateY(${scrollCoeff}px)`;
    document.querySelector('.footer__copyright').style.transform = `translateY(${scrollCoeff}px)`;
  } else {
    footerSection.style.position = 'static';
    footerSection.style.opacity = 1;
  }
};
